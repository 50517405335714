import Vue from "vue";
import Vuex from "vuex";

// 分模块引入
import common from "./common"; // 公共
import menu from "./menu"; // 菜单

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        common,
        menu,
    },
});

export default store;
