export default {
    responseCode: {
        SUCCESS: "0", // 成功
        PARAMSERR: "00000005", // 请求参数出错
        NEWLOGIN: "70000001", // 账号同时登录(需要重新登录)
        REFRESHTOKEN: "20000000", // token需刷新(需要刷新token)
        TOKENEXPIRED: "20000001", // token过期(长时间不操作，需要重新登录)
        TOKENEXPIREDNEW: "71302030", // 新的token过期(长时间不操作，需要重新登录)
    },
};
