import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "@/gobal/css/theme.less";
import config from "../static/config";
import VueI18n from "vue-i18n";
import store from "./store/index";
import util from "./lib/util";
import axios from 'axios'

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: "zh-CN", // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
    messages: {
        "zh-CN": require("./i18n/zh-CN"), // 中文语言包
        "en-US": require("./i18n/en-US"), // 英文语言包
    },
});

Vue.config.productionTip = false;
Vue.prototype = Vue.prototype = Object.assign(Vue.prototype, config);
Vue.prototype.$util = util;

const instance = axios.create({
    headers: {'Content-Type': 'application/json;charset=UTF-8', 'Accept': '*/*'}
});
// axios.defaults.headers.post['Content-Type'] = 'text/plain;charset=UTF-8'
Vue.prototype.$axios = instance;
// Vue.prototype.$router = router;
Vue.use(ElementUI);

new Vue({
    i18n,
    router: router,
    store,
    render: (h) => h(App),
    created() {
        util.ajax(this);
    },
}).$mount("#app");

// rem 屏幕尺寸
const setHtmlFontSize = () => {
    const htmlDom = document.getElementsByTagName("html")[0];
    let htmlWidth =
        document.documentElement.clientWidth || document.body.clientWidth;
    // if (htmlWidth >= 1920) {
    //     htmlWidth = 1920;
    // }
    if (htmlWidth <= 1366) {
        htmlWidth = 1366;
    }
    htmlDom.style.fontSize = `${htmlWidth / 10}px`;
};
window.onresize = setHtmlFontSize;
setHtmlFontSize();
