import Vue from "vue";
import VueRouter from "vue-router";

import Login from "@/views/login";
import Main from "@/views/main";
// import Home from "@/views/home";
import Demo from "@/views/demo";

import UserManager from "@/views/system/user-manager";
import RoleManager from "@/views/system/role-manager";
import LeaveMessage from '@/views/leave-message'
// import BigScreen1 from '@/views/bigScreen/bigScreen1'
// import BigScreen11 from '@/views/bigScreen/bigScreen1-1'
import Undefind from "@/views/undefind";
import OfficialWebsite from '@/views/official-website/official-website'

Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: "/",
            name: "officialWebsite",
            component: OfficialWebsite,
        },
        // {
        //     path: "/bigScreen1-white",
        //     name: "bigScreen1-white",
        //     component: BigScreen11,
        // },
        {
            path: "/login",
            name: "login",
            component: Login,
        },
        {
            path: "/404",
            name: "404",
            component: Undefind,
        },
        
        {
            path: "/vmain",
            name: "vmain",
            component: Main,
            children: [
                {
                    path: "/home",
                    name: "demo",
                    // component: Home,
                    component: LeaveMessage
                },
                {
                    path: "/demo",
                    name: "demo",
                    component: Demo,
                },
                {
                    path: "/roleManager",
                    name: "roleManager",
                    component: RoleManager,
                },
                {
                    path: "/userManager",
                    name: "userManager",
                    component: UserManager,
                },
                {
                    path: '/leaveMessage',
                    name: 'leaveMessage',
                    component: LeaveMessage
                }
            ],
        },
    ],
});

// router.beforeEach((to, from, next) => {
//     console.log(to.name);
//     if (!to.name) {
//         next({ name: "404" });
//     } else if (!localStorage.getItem("token") && to.name !== "login" && to.name !== 'OfficialWebsite') {
//         next({ name: "login" });
//     } else {
//         next();
//     }
// });

export default router;
