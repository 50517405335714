<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style lang="less">
* {
    margin: 0;
    padding: 0;
}
html, body, #app {
    width: 100%;
    height: 100%;
}
body {
    font-size: 14px;
}
</style>
