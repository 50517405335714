<!--  -->
<template>
    <div class="official-website-box">
        <el-dropdown class="lang-box" @command="handleLang">
            <span class="el-dropdown-link">
                {{langName}}<em class="el-icon-arrow-down el-icon--right"></em>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in langList" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
            <!-- <img class="top-bg" src="@/assets/home-bg.png" alt=""> -->
        <template v-if="langId !== 'arabic'">
            <div class="header-content">
                
                <div class="header-top-tip">
                    <img class="login-icon" src="@/assets/login-icon.png" alt="">
                    <span>{{ $t('lang.text1') }}</span>
                </div>
                <div class="header-des">
                    {{ $t('lang.text2') }}
                </div>
                <div class="header-text">
                    {{ $t('lang.text3') }}
                </div>
            </div>
            <div class="content-1">
                <div class="content-1-header-title center">
                    {{ $t('lang.text4') }}
                </div>
                <div class="content-1-header-tip center">
                    {{ $t('lang.text5') }}
                </div>
                <div class="content-1-content">
                    <div class="content-1-content-item">
                        <div class="content-1-content-item-content">
                            <div class="title">{{ $t('lang.text6') }}</div>
                            <ul>
                                <li>{{ $t('lang.text7') }}</li>
                                <li>{{ $t('lang.text8') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="content-1-content-item white">
                        <div class="content-1-content-item-content">
                            <div class="title">{{ $t('lang.text9') }}</div>
                            <ul>
                                <li>{{ $t('lang.text10') }}</li>
                                <li>{{ $t('lang.text11') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="content-1-content-item white">
                        <div class="content-1-content-item-content">
                            <div class="title">{{ $t('lang.text12') }}</div>
                            <ul>
                                <li>{{ $t('lang.text13') }}</li>
                                <li>{{ $t('lang.text14') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="content-1-content-item">
                        <div class="content-1-content-item-content">
                            <div class="title">{{ $t('lang.text15') }}</div>
                            <ul>
                                <li>{{ $t('lang.text16') }}</li>
                                <li>{{ $t('lang.text17') }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-2">
                <div class="content-2-left">
                    <div class="content-2-left-item-1 content-2-card-item">
                        <div>
                            <div class="title">
                                {{ $t('lang.text18') }}
                            </div>
                            <div class="content">
                                {{ $t('lang.text19') }}
                            </div>
                        </div>
                        
                    </div>
                    <div class="content-2-left-item-2 content-2-card-item">
                        <div class="title">
                            {{ $t('lang.text20') }}
                        </div>
                        <div class="content">
                            {{ $t('lang.text21') }}
                        </div>
                    </div>
                </div>
                <img src="@/assets/img-1.png" alt="">
                <div class="content-2-right">
                    <div class="content-2-right-item-1 content-2-card-item">
                        <div class="title">{{ $t('lang.text22') }}</div>
                        <div class="content">{{ $t('lang.text23') }}</div>
                    </div>
                    <div class="content-2-right-item-2 content-2-card-item">
                        <div class="title">{{ $t('lang.text24') }}</div>
                        <div class="content">{{ $t('lang.text25') }}</div>
                    </div>
                </div>
            </div>
            <div class="content-3 content-common">
                <div class="content-1-header-title center">
                    {{ $t('lang.text26') }}
                </div>
                <div class="content-1-header-tip center">
                    {{ $t('lang.text27') }}
                </div>
                <div class="content-3-content">
                    <div class="content-3-item">
                        <img src="@/assets/img-2.png" alt="">
                        <div class="content-3-item-content">
                            <div class="title">{{ $t('lang.text28') }}</div>
                            <div class="content">{{ $t('lang.text29') }}</div>
                        </div>
                    </div>
                    <div class="content-3-item">
                        <img src="@/assets/img-5.png" alt="">
                        <div class="content-3-item-content">
                            <div class="title">{{ $t('lang.text30') }}</div>
                            <div class="content">{{ $t('lang.text31') }}</div>
                        </div>
                    </div>
                    <div class="content-3-item">
                        <div class="content-3-item-content">
                            <div class="title">{{ $t('lang.text32') }}</div>
                            <div class="content">{{ $t('lang.text33') }}</div>
                            <div class="content content-11">{{ $t('lang.text34') }}</div>
                        </div>
                        <img src="@/assets/img-3.png" alt="">
                        
                    </div>
                    <div class="content-3-item">
                        <div class="content-3-item-content">
                            <div class="title">{{ $t('lang.text35') }}</div>
                            <div class="content">{{ $t('lang.text36') }}</div>
                        </div>
                        <img src="@/assets/img-4.png" alt="">
                    </div>
                </div>
                
            </div>
            <div class="content-4 content-common">
                <div class="content-1-header-title center">
                    {{ $t('lang.text37') }}
                </div>
                <div class="content-1-header-tip center">
                    {{ $t('lang.text38') }}
                </div>
                <div class="content-4-content">
                    <div class="content-4-content-item">
                        <div class="content-4-content-item-left">
                            <img src="@/assets/img-9.png" alt="">
                        </div>
                        <div class="content-4-content-item-right">
                            <div class="title">
                                {{ $t('lang.text39') }}
                            </div>
                            <div class="content">
                                <ul>
                                    <li>{{ $t('lang.text40') }}</li>
                                    <li>{{ $t('lang.text41') }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="content-4-content-item">
                        <div class="content-4-content-item-left">
                            <img src="@/assets/img-7.png" alt="">
                        </div>
                        <div class="content-4-content-item-right">
                            <div class="title">
                                {{ $t('lang.text42') }}
                            </div>
                            <div class="content">
                                <ul>
                                    <li>{{ $t('lang.text43') }}</li>
                                    <li>{{ $t('lang.text44') }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="content-4-content-item">
                        <div class="content-4-content-item-left">
                            <img src="@/assets/img-8.png" alt="">
                        </div>
                        <div class="content-4-content-item-right">
                            <div class="title">
                                {{ $t('lang.text45') }}
                            </div>
                            <div class="content">
                                <ul>
                                    <li>{{ $t('lang.text46') }}</li>
                                    <li>{{ $t('lang.text47') }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="content-4-content-item">
                        <div class="content-4-content-item-left">
                            <img src="@/assets/img-6.png" alt="">
                        </div>
                        <div class="content-4-content-item-right">
                            <div class="title">
                                {{ $t('lang.text48') }}
                            </div>
                            <div class="content">
                                <ul>
                                    <li>{{ $t('lang.text49') }}</li>
                                    <li>{{ $t('lang.text50') }}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-5 content-common">
                <div class="content-1-header-title center">
                    {{ $t('lang.text51') }}
                </div>
                <div class="content-1-header-tip center">
                    {{ $t('lang.text52') }}
                </div>
                <div class="content-5-content">
                    <div class="content-5-content-item">
                        <img class="icon" src="@/assets/img-13.png" alt="">
                        <div class="title">
                            {{ $t('lang.text53') }}
                        </div>
                        <div class="content">
                            <ul>
                                <li>{{ $t('lang.text54') }}</li>
                                <li>{{ $t('lang.text55') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="content-5-content-item">
                        <img class="icon" src="@/assets/img-12.png" alt="">
                        <div class="title">
                            {{ $t('lang.text56') }}
                        </div>
                        <div class="content">
                            <ul>
                                <li>{{ $t('lang.text57') }}</li>
                                <li>{{ $t('lang.text58') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="content-5-content-item">
                        <img class="icon" src="@/assets/img-10.png" alt="">
                        <div class="title">
                            {{ $t('lang.text59') }}
                        </div>
                        <div class="content">
                            <ul>
                                <li>{{ $t('lang.text60') }}</li>
                                <li>{{ $t('lang.text61') }}</li>
                            </ul>
                        </div>
                    </div>
                    <div class="content-5-content-item">
                        <img class="icon" src="@/assets/img-11.png" alt="">
                        <div class="title">
                            {{ $t('lang.text62') }}
                        </div>
                        <div class="content">
                            <ul>
                                <li>{{ $t('lang.text63') }}</li>
                                <li>{{ $t('lang.text64') }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-6">
                <img class="img" :src="langId === 'zh-CN' ? bg1 : bg2" alt="">
            </div>
        </template>
        <template v-else>
            <img class="alb-img" src="@/assets/alb.png" alt="">
        </template>
        



        <div class="content-7">
            <div class="content-7-left">
                <img src="@/assets/img-17.png" alt="">
                <el-form ref="formRef" :rules="rules" :model="formInline" class="demo-form-inline">
                    <el-row :gutter="16">
                        <el-col :span="8">
                            <el-form-item :label="$t('lang.text65')" prop="username">
                                <el-input v-model="formInline.username" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('lang.text66')" prop="telphone">
                                <el-input v-model="formInline.telphone" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item :label="$t('lang.text67')" prop="email">
                                <el-input v-model="formInline.email" placeholder="Please input"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- <br /> -->
                    <el-form-item :label="$t('lang.text68')" prop="consultation">
                        <el-input v-model="formInline.consultation" placeholder="Please input"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="submit" type="primary" @click="onSubmit">{{ $t('lang.text69') }}</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content-7-right">
                <div class="center">
                    <img class="code" src="@/assets/code.png" alt="">
                </div>
                <div class="center content-7-right-tip"> Wechat public account</div>
                <div class="left content-7-right-text"> Telephone：+971 50 9652788</div>
                <div class="left content-7-right-text"> Email： huangxiaohui2@cqkunfly.com</div>
                <div class="left content-7-right-text content-7-right-text-email-other"> y008613883152786@163.com</div>
            </div>
            <!-- <div class="content-7-header-title center">
                {{ $t('lang.text64') }}
            </div>
            <div class="content-7-header-tip center">
                {{ $t('lang.text65') }}
            </div>
            <div class="content-7-content">
                <div class="button" @click="dialogVisible = true">
                    <img src="@/assets/img-15.png" alt="">
                    留言咨询
                </div>
            </div> -->
        </div>
        
        <!-- <el-dialog
            title="留言"
            :visible.sync="dialogVisible"
            width="40%">
            <el-input
                type="textarea"
                :autosize="{ minRows: 6, maxRows: 10}"
                placeholder=""
                v-model="textarea2">
            </el-input>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog> -->
          <!-- {{$t('lang.home')}} -->
    </div>
  </template>
  <script>
  import bg1 from '@/assets/img-14.png'
  import bg2 from '@/assets/img-16.png'
  export default {
    name: '',
    components: {
      
    },
    props: {},
    computed: {
        langName() {
            let result = ''
            for(let i = 0; i < this.langList.length; i++) {
                if (this.langId === this.langList[i].value) {
                    result = this.langList[i].label;
                    break
                }
            }
            return result
        },
    },
    data () {
      return {
        bg1,
        bg2,
        langId: 'zh-CN',
        langList: [
            {label: '中文', value: 'zh-CN'},
            {label: '英文', value: 'en-US'},
            {label: '阿拉伯语', value: 'arabic'}
        ],
        dialogVisible: false,
        formInline: {
            username: '',
            telphone: '',
            email: '',
            consultation: ''
        },
        rules: {
            username: [
                { required: true, message: 'Please input', trigger: 'blur' }
            ],
            telphone: [
                { required: true, message: 'Please input', trigger: 'blur' }
            ],
            email: [
                { required: false, message: 'Please input', trigger: 'blur' }
            ],
            consultation: [
                { required: true, message: 'Please input', trigger: 'blur' }
            ]
        }
      }
    },
    mounted () {
    },
    methods: {
        handleLang(val) {
            this.langId = val;
            console.log(this.langId)
            this.$i18n.locale = val;
        },
        onSubmit() {
            this.$refs['formRef'].validate((valid) => {
                if (valid) {
                    this.$axios.post(this.baseUrl + '/leavingMessage/add', {
                        "consult": this.formInline.consultation,
                        "email": this.formInline.email,
                        "name": this.formInline.username,
                        "phone": this.formInline.telphone,
                    })
                    .then(() => {
                        this.$message({
                            message: '留言成功',
                            type:'success'
                        });
                        this.formInline.consultation = '';
                        this.formInline.email = '';
                        this.formInline.username = '';
                        this.formInline.telphone = '';
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    },
  }
  
  </script>
  
  <style lang='less'>
.official-website-box {
    .left {
        text-align: left;
    }
    .center {
        text-align: center;
    }
    .right {
        text-align: right;
    }
    .lang-box {
        position: absolute;
        top: 42/192rem;
        right: 128/192rem;
        .el-dropdown-link {
            color: white;
            font-size: 16/192rem;
        }
    }
    .alb-img {
        width: 100%;
    }
    .header-content {
        background-image: url("../../assets/home-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 900/192rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        
        .login-icon {
            width: 64/192rem;
            height: 58/192rem;
            margin-right: 12/192rem;
        }
        .header-top-tip {
            display: flex;
            align-items: center;
            font-family: Alimama ShuHeiTi;
            font-size: 48/192rem;
            font-weight: bold;
            /* 白色 */
            color: #FFFFFF;
        }
        .header-des {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 8/192rem 40/192rem;
            background: #264E8E;
            font-size: 18/192rem;
            color: #FFFFFF;
            border-radius: 41/192rem;
            margin-top: 30/192rem;
        }
        .header-text {
            font-size: 14/192rem;
            /* 副标题 */
            color: #E6EBF3;
            width: 712/192rem;
            margin-top: 30/192rem;
            text-align: center;
        }
    }
    .content-1 {
        margin: 80/192rem 120/192rem 0 120/192rem;
        .content-1-header-title {
            font-family: Alimama ShuHeiTi;
            font-size: 30/192rem;
            font-weight: bold;
            line-height: normal;
            letter-spacing: 0em;
            /* 蓝色 */
            color: #264E8E;
        }
        .content-1-header-tip {
            font-size: 20/192rem;
            font-weight: bold;
            line-height: normal;
            text-transform: uppercase;
            letter-spacing: 0em;
            color: #E6EBF3;
        }
        .content-1-content {
            display: flex;
            flex-wrap: wrap;
            margin-top: 40/192rem;
            &-item {
                &-content {
                    margin: 45/192rem 80/192rem 45/192rem 50/192rem;
                }
                width: 50%;
                /* 蓝色 */
                background: #264E8E;
                .title {
                    /* 白色 */
                    color: #FFFFFF;
                    font-size: 15/192rem;
                    font-weight: bold;
                    line-height: normal;
                    letter-spacing: 0em;
                }
                ul {
                    /* 白色 */
                    color: #FFFFFF;
                    font-size: 13/192rem;
                    margin-top: 20/192rem;
                }
            }
            .white {
                background: #F6F7F9;
                .title {
                    color: #3F4B5E;
                }
                ul {
                    color: #8996AA;
                }
            }
        }
    }
    .content-2 {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 70/192rem;
        img {
            width: 275/192rem;
            height: 344/192rem;
        }
        .content-2-left {
            display: block;
            .content-2-left-item-1 {
                display: inline-block;
                margin-left: 120/192rem;
                margin-bottom: 100/192rem;
            }
            .content-2-left-item-2 {
                margin-right: 100/192rem;
            }
        }
        
        .content-2-right-item-1 {
            margin-left: 110/192rem;
            margin-bottom: 100/192rem;
        }
        .content-2-right-item-2 {
            margin-left: 100/192rem;
        }
        .content-2-card-item {
            padding: 8/192rem 16/192rem;
            background: #F6F7F9;
            .title {
                font-size: 16/192rem;
                /* 蓝色 */
                color: #264E8E;
            }
            .content {
                font-size: 14/192rem;
                color: #BAC9E1;
            }
        }
    }
    .content-3 {
        margin: 70/192rem 120/192rem 0 120/192rem;
        .content-3-content {
            margin-top: 40/192rem;
            display: flex;
            flex-wrap: wrap;
        }
        &-item:nth-child(2n + 1) {
            justify-content: flex-end;
        }
        &-item {
            width: 50%;
            height: 250/192rem;
            display: flex;
            img {
                width: 300/192rem;
                height: 250/192rem;
            }
            .content-3-item-content {
                padding: 32/192rem 30/192rem;
                width: 240/192rem;
                background: #F6F7F9;
                .title {
                    text-align: center;
                    font-size: 15/192rem;
                    color: #3F4B5E;
                }
                .content {
                    margin-top: 16/192rem;
                    font-size: 13/192rem;
                    color: #8996AA;
                }
                .content-11 {
                    margin-top: 10/192rem;
                }
            }
        }
    }
    .content-4 {
        margin: 70/192rem 120/192rem 0 120/192rem;
        .content-4-content {
            margin-top: 40/192rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .content-4-content-item {
                display: flex;
                margin-bottom: 26/192rem;
                .content-4-content-item-left {
                    img {
                        width: 143/192rem;
                        height: 191/192rem;
                    }
                }
                .content-4-content-item-right {
                    width: 396/192rem;
                    height: 143/192rem;
                    padding: 30/192rem 24/192rem;
                    background: #F7F8FA;
                    .title {
                        font-size: 15/192rem;
                        font-weight: bold;
                        margin-bottom: 16/192rem;
                    }
                    .content {
                        font-size: 13/192rem;
                        color: #8996AA;
                        padding-left: 20/192rem;
                        li {
                            margin-top: 15/192rem;
                        }
                    }
                }
            }
            .content-4-content-item:nth-child(2n+1) {
                margin-right: 24/192rem;
            }
        }
    }
    .content-5 {
        margin-top: 70/192rem;
        padding: 60/192rem 0;
        background: #F6F9FF;
        .content-5-content {
            display: flex;
            justify-content: center;
            margin-top: 74/192rem;
            .content-5-content-item {
                width: 215/192rem;
                padding: 40/192rem 25/192rem 40/192rem 40/192rem;
                position: relative;
                background: #E6EEFF;
                margin: 0 13/192rem;
                .title {
                    color: #3F4B5E;
                    font-weight: bold;
                    font-size: 15/192rem;
                    text-align: center;
                    margin-bottom: 16/192rem;
                }
                .content {
                    font-size: 13/192rem;
                    color: #8996AA;
                    ul {
                        li {
                            line-height: 21/192rem;
                            margin-top: 15/192rem
                        }
                    }
                }
                .icon {
                    position: absolute;
                    top: -35/192rem;
                    left: 110/192rem;
                    width: 70/192rem;
                    height: 70/192rem;
                }
            }

        }
    }
    .content-6 {
        margin: 70/192rem 0;
        text-align: center;
        .img {
            height: 514/192rem;
        }
    }
    .content-7 {
        display: flex;
        margin-top: 70/192rem;
        padding: 40/192rem 120/192rem;
        background: linear-gradient(270deg, #264E8E 0%, #2A5EB2 98%);
        height: 434/192rem;
        .content-7-header-title {
            font-size: 40/192rem;
            font-weight: bold;
            /* 白色 */
            color: #FFFFFF;
            margin-top: 70/192rem;
            display: inline-block;
            width: 100%;
        }
        .content-7-header-tip {
            margin-top: 10/192rem;
            font-size: 26/192rem;
            font-weight: bold;
            color: #ccc;
        }
        .content-7-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 32/192rem;
            .button {
                width: 295/192rem;
                height: 52/192rem;
                border-radius: 29/192rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #FFFFFF;
                font-size: 18/192rem;
                color: #264E8E;
                cursor: pointer;
                img {
                    margin-right: 8/192rem;
                    width: 20/192rem;
                    height: 18/192rem;
                }
            }
        }
        .content-7-left {
            flex: 1;
            img {
                width: 120/192rem;
                height: 34.69/192rem;
            }
            .el-row {
                .el-col {
                    padding-left: 8/192rem !important;
                    padding-right: 8/192rem !important;
                }
            }
            .el-form {
                margin-top: 40/192rem;
                .el-form-item {
                    margin-bottom: 22/192rem !important;
                }
                .el-form-item__content {
                    line-height: 0;
                }
                .el-form-item__label {
                    font-size: 14/192rem;
                    color: #FFFFFF;
                    line-height: 40/192rem;
                }
                .el-input {
                    
                    .el-input__inner {
                        min-height: 45/192rem;
                        height: 45/192rem;
                        font-size: 14/192rem;
                    }
                }
            }
            .submit {
                width: 208/192rem;
                height: 50/192rem;
                /* 自动布局 */
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 5/192rem 40/192rem;
                /* 白色 */
                background: #FFFFFF;
                font-size: 16/192rem;
                color: #264E8E;
                margin-top: 24/192rem;
            }
        }
        .content-7-right {
            width: 30%;
            font-size: 14/192rem;
            color: #FFFFFF;
            .code {
                width: 100/192rem;
                height: 100/192rem;
                margin-top: 100/192rem;
            }
            .content-7-right-tip {
                margin-bottom: 24/192rem;
                margin-top: 5/192rem;
            }
            .content-7-right-text {
                padding-left: 148/192rem;
                line-height: 30/192rem;
            }
            .content-7-right-text-email-other {
                padding-left: 200/192rem;
            }
        }
    }

    .content-common {
        .content-1-header-title {
            font-family: Alimama ShuHeiTi;
            font-size: 30/192rem;
            font-weight: bold;
            line-height: normal;
            letter-spacing: 0em;
            /* 蓝色 */
            color: #264E8E;
        }
        .content-1-header-tip {
            font-size: 20/192rem;
            font-weight: bold;
            line-height: normal;
            text-transform: uppercase;
            letter-spacing: 0em;
            color: #E6EBF3;
        }
    }
}
.el-dropdown-menu {
    .el-dropdown-menu__item {
        line-height: 36/192rem !important;
        padding: 0 20/192rem !important;
        font-size: 14/192rem !important;
    }
}
  </style>
  