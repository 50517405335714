import Vue from "vue";
import axios from "axios";
import $ from "../jquery";
import base from "./base";
const util = {};

Vue.prototype.base = base;

const ajaxUrl = "";
util.ajax = (vm) => {
    window.ajaxConfig = Object.assign({}, window.ajaxConfig);
    var utilAjax = axios.create({
        baseURL: ajaxUrl,
        timeout: 30000,
    });
    // 自动构造请求头
    utilAjax.interceptors.request.use((config) => {
        config.timeout = window.ajaxConfig.timeOut || 30000;
        config.headers["Accept"] = "*/*"; // 低版本的火狐问题
        if (config.url.indexOf("deviceVersionManage/add") > -1) {
            // 上传二进制文件
            config.headers = {
                "Content-Type": undefined,
            };
            config.timeout = 60 * 60 * 1000 * 10;
        } else {
            if (config.method === "post" && !config.headers["Content-Type"]) {
                config.headers = {
                    "Content-Type": "application/json",
                };
            }
        }
        var token = localStorage["token"];
        if (config.url.indexOf("auth/token") !== -1) {
            token = localStorage["refreshToken"];
        }
        if (token) {
            config.headers["X-Authorization"] = "Bearer " + token;
        } else {
            console.log("丢失token!");
        }
        // 唯一标识
        config.headers["traceId"] = util.uuid();
        return config;
    });
    // 自动处理响应
    utilAjax.interceptors.response.use(
        (response) => {
            var data = response.data;
            var status = response.status;

            if (status === 200) {
                // 其他地方登录账号 或 长时间未操作，退出到登录页面
                if (
                    data.code === vm.base.responseCode.NEWLOGIN ||
                    data.code === vm.base.responseCode.TOKENEXPIRED ||
                    response.data.code === vm.base.responseCode.TOKENEXPIREDNEW
                ) {
                    vm.$message.error("登录信息已失效，请重新登录");
                    setTimeout(() => {
                        vm.$store.dispatch("getLogout", [
                            vm,
                            utilAjax.config.url,
                        ]);
                    }, 3000);
                    // vm.$store.commit('desWS', vm);
                } else {
                    return Promise.resolve(response);
                }
            } else {
                return Promise.reject(response);
            }
        },
        (error) => {
            console.log(error);
            var response = error.response;
            var request = error.request;
            var message = "请求异常";
            if (response) {
                if (response.data && response.data.message) {
                    vm.$message.error(response.data.message);
                } else {
                    vm.$message.error(message);
                }
            } else if (request && request.status === 0) {
                vm.$message.error(vm.$t("noServer"));
            } else {
                vm.$message.error(message);
            }
            if (!response) {
                response = {};
            }
            if (!response.data) {
                response.data = {};
            }
            response.data._messageFlag = true;
            return Promise.resolve(response);
        }
    );

    Vue.prototype.$http = utilAjax;
};

// post封装
util.post = function(vm, obj) {
    obj = $.extend(
        {
            commonComplete: true, // error与catch都使用complete
            errorInfo: true, // 显示错误信息
            url: "",
            async: false,
            params: {},
            config: null,
            success: function() {},
            error: function(response) {
                if (
                    response.code !== vm.base.responseCode.PARAMSERR &&
                    response.message
                )
                    vm.$message({
                        type: "error",
                        message: response.message,
                        offset: 100,
                    });
            },
            complete: function() {},
        },
        obj
    );
    vm.$http
        .post(obj.url, obj.params, obj.config)
        .then((response) => {
            console.log(obj);
            console.log(response);
            if (response && response.data) {
                if (obj.config !== null) {
                    obj.success(response.data);
                } else {
                    if (
                        response.data.code ===
                            vm.base.responseCode.REFRESHTOKEN ||
                        response.data.code ===
                            vm.base.responseCode.TOKENEXPIRED ||
                        response.data.code ===
                            vm.base.responseCode.TOKENEXPIREDNEW
                    ) {
                        vm.$message.error("登录信息已失效，请重新登录");
                        setTimeout(() => {
                            vm.$store.dispatch("getLogout", [vm, obj.url]);
                        }, 3000);

                        // vm.$store.commit('desWS', vm);
                    } else if (
                        response.data.code === vm.base.responseCode.PARAMSERR
                    ) {
                        // 请求参数错误
                        var message = "";
                        if (
                            response.data.error &&
                            response.data.error.length > 0
                        ) {
                            response.data.error.forEach((element) => {
                                message += " " + element.defaultMessage;
                            });
                            vm.$message.error(message);
                            obj.error(response.data); // 参数错误也应该进失败回调
                        } else {
                            vm.$message.error(response.data.message);
                            obj.error(response.data); // 参数错误也应该进失败回调
                        }
                    } else if (
                        response.data.code === vm.base.responseCode.SUCCESS
                    ) {
                        // 成功
                        obj.success(response.data);
                    } else {
                        // 失败
                        if (obj.errorInfo) {
                            obj.error(response.data);
                        }
                    }
                }
            } else {
                console.log("response响应为空！");
            }
            obj.complete(response.data);
        })
        .catch((error) => {
            // 其他错误信息待规整
            if (obj.commonComplete) {
                obj.complete();
            }
            throw error;
        });
};

util.uuid = () => {
    let s = [];
    let hexDigits = "0123456789abcdef";
    for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = "-";

    let uuid = s.join("");
    return uuid;
};

export default util;
