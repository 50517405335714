<template>
  <div class="login-box">
      <div class="login-bg">
        鲲之翼信息科技
      </div>
      <div class="login-content">
          <div class="login-content-container">
              <h3>用 户 登 录</h3>
              <el-form :model="loginForm" :rules="rules" ref="loginForm">
                <el-form-item label="用户名" prop="username">
                    <el-input v-model="loginForm.username" placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="loginForm.password" type="password" placeholder="请输入密码"></el-input>
                </el-form-item>
              </el-form>
              <el-button type="primary" style="width: 30%" @click="login">登 录</el-button>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            loginForm: {
                username: '',
                password: ''
            },
            rules: {
                username: [{required: true, message:'请输入用户名', trigger: 'blur'}],
                password: [{required: true, message:'请输入密码', trigger: 'blur'}]
            }
        }
    },
    methods: {
        login() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    // 模拟登录
                    localStorage.setItem('token', '123456')
                    localStorage.setItem('userkey', this.loginForm.username)
                    this.$router.push({name: 'leaveMessage'})
                    console.log('login...')
                }
            });
        }
    }
}
</script>

<style lang="less" scoped>
    .login-box {
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 14px;
        .login-bg {
            background-image: url('../../assets/login-bg.png');
            width: 45%;
            height: 100%;
            max-height: 100%;
            line-height: 1;
            font-size: 72/192rem;
            font-weight: bold;
            color: #FFFFFF;
            text-indent: .5em;
            display: flex;
            align-items: center;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
        }
        .login-content {
            display: flex;
            flex-grow: 1;
            height: 100%;
            justify-content: center;
            align-items: center;
            &-container {
                width: 70%;
                height: 300px;
                text-align: center;
                h3 {
                    color: #262626;
                }
            }
        }
    }
</style>