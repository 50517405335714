<template>
  <div class="demo-box">demo</div>
</template>

<script>
export default {

}
</script>

<style lang="less">
    .demo-box {
        width: 100%;
        height: 100%;
    }
</style>