export default {
    state: () => ({
        menuList: [
            {
                name: "留言",
                resourceId: "1",
                rescourceCode: "leaveMessage",
            }
            // { name: "首页", resourceId: "1", rescourceCode: "home" },
            // {
            //     name: "系统管理",
            //     resourceId: "2",
            //     children: [
            //         {
            //             name: "用户管理",
            //             resourceId: "2-1",
            //             rescourceCode: "userManager",
            //         },
            //         {
            //             name: "角色管理",
            //             resourceId: "2-2",
            //             rescourceCode: "roleManager",
            //         },
            //         {
            //             name: "配置管理",
            //             resourceId: "2-3",
            //             children: [
            //                 { name: "参数配置", resourceId: "2-3-1" },
            //                 { name: "属性配置", resourceId: "2-3-2" },
            //             ],
            //         },
            //     ],
            // },
        ],
    }),
    mutations: {},
    actions: {},
    getters: {
        menuList(state) {
            return state.menuList || [];
        },
    },
};
