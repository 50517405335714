export const lang = {
    text1: '重庆鲲之翼信息科技有限公司',
    text2: '专注于软件研发、智能设备运营及相关软硬件研发',
    text3: '我们秉承专业、敬业、激情、创新的发展理念，坚持以客户为本、以信用为先的服务准则，以自身擅长企业信息化服务的优势，用心解决用户最迫切、最实际的需求，以先进的技术为用户提供完美的服务。',
    text4: '测试与数字化的质量管控',
    text5: 'TESTING & DIGITAL ASSURANCE',
    text6: '1. 通过现代化测试服务让业务快速上市',
    text7: '我们推荐客户加速向现代质量工程实践转型。',
    text8: '我们通过提供智能测试平台帮助客户提升软件质量管理能力，向更高水平的自动化、更高的生产力和速度迈进。',
    text9: '2. 结果导向的测试即服务带来更大价值',
    text10: '我们为客户提供独特的基于结果的“随用随付”模式，可灵活优化和控制支出，根据不断变化的业务需求扩大或缩小规模。',
    text11: '我们帮助客户在做出风险较小的业务决策之前了解确切的预期结果和合同交付成果。',
    text12: '3. 提供端到端测试全方位提高应用程序质量',
    text13: '我们提供广泛的端到端的服务目录，实现 360° 全方位的应用程序质量视图，包括内置的功能、集成、性能、可靠性和安全性测试。',
    text14: '灵活的可选择的测试方法更易于管理，并让客户能够选择满足其最高优先级需求的服务。',
    text15: '4. 曾为多家财富 500 强公司提供极具价值的服务',
    text16: '我们正布局全球业务，拥有上百名测试从业人员，提供多种语言服务，提供具有性价比的测试服务。',
    text17: '我们有经过数以百计客户验证的成功经验，拥有多年的各行业经验。',
    text18: '测试咨询',
    text19: 'TESTING ADVISORY',
    text20: '自动化智能测试',
    text21: 'INTELLIGENT TEST AUTOMATION',
    text22: '智能测试即服务',
    text23: 'INTELLIGENT TESTING AS A SERVICE',
    text24: '按需提供应用程序安全',
    text25: 'APPS SECURITY ON DEMAND',
    text26: '现代数字化转型方案设计',
    text27: 'APPLICATIONS MODERNIZATION',
    text28: '节约 39% 以上的IT技术投入 不再需要为高昂的技术服务买单',
    text29: '我们可为您全面诊断面向业务的信息技术问题，可为您随时发现和定位在各类IT环境的上下游系统中产生的问题，同时我们也提供敏捷精准的解决方案交付，在解决您业务痛点的同时也为您随时随地跟踪和节省开展业务产生的投入。',
    text30: '为业务带来数字化创新 可为 47% 以上传统方案带来升级',
    text31: '我们带来的数字化综合集成方案可以为您的企业业务系统带来更优质的数据治理，业务流程编排和IT服务内容的升级，让您的企业业务在运营中随时保持最新的IT技术匹配，以及沉淀出最有价值的数字化资产。',
    text32: '提升 42% 以上 客户需求的交付速度',
    text33: '在10天内为客户快速提出高效执行计划。',
    text34: '我们可根据多年来为650多家各行业客户服务所总结的经验和和解决方案数据库，快速智能匹配到适合您业务的解决方案和成熟案例供您参考。',
    text35: '降低业务风险 可修复45% 以上的不可持续的服务风险',
    text36: '我们可为您全面诊断面向业务的应用服务的可持续性分析，测试和发现相关影响服务持续性的问题。同时为您提供专业的应用系统扩展和重构服务，以降低和消弭弹性服务所带来的风险问题。',
    text37: '应用开发和运维服务',
    text38: 'MANAGED APPLICATIONS',
    text39: '1. 带来可预见的预算节省',
    text40: '我们可通过提供为相关业务提供智能运维服务，最多能节省40%的运维成本。',
    text41: '我们可为您根据业务现状简化、优化、自动化应用运维相关工作，并搭建可持续运营和数字化转型的IT服务解决方案。',
    text42: '2. 带来业务价值的快速增长',
    text43: '我们多年的服务经验可为您的业务带来越来越快速的的价值增长以及越来越低的信息化相关投入。',
    text44: '专注目标导向的服务方式，搭建IT服务提供更加敏捷的业务价值实现。',
    text45: '3. 带来更好的运营稳定性',
    text46: '我们可为您的业务搭建基于AI的自动化解决方案，建立和提升运营的高效和高可用。',
    text47: '我们可为您搭建集中应用运维解决方案，消除运维工作中的数据和信息壁垒，建立起复杂系统应用运维的关联信息，极大地降低平均故障修复时间（MTTR）。',
    text48: '4. 带来可扩展、高弹性的服务交付',
    text49: '我们可为您提供基于行业成熟的方法论的解决方案和相关技术服务交付。',
    text50: '我们具备为全球Top 1000客户服务的各领域专家和交付工程师。',
    text51: '鲲之翼现代数字化应用开发',
    text52: 'MODERN APPLICATIONS DEVELOPMENT',
    text53: '平台专家服务',
    text54: '我们能带领客户逐步完成数字化转型，通过和客户共同迈向下一代的以“平台驱动业务”的方式优化他们的运营模式，以达到在市场竞争中所需的速度和敏捷性。',
    text55: '我们有着丰富的行业经验和多年沉淀的技术资源以帮助客户快速获得解决方案，同时帮助客户在组织上适应并在技术上进行转型，以融入网络化和物联网软件定义的世界。',
    text56: '共同应对商业机遇',
    text57: '在一些具有机遇商业项目上我们和客户风雨同舟共同承担责任，并共同探索找到一种对我们的客户和我们都有益的可持续商业模式。',
    text58: '我们认为，我们和客户一起为商业机会打造平台的方法，可以提供一种双方一致认可的从交付到运营服务的总拥有成本的最优解。',
    text59: '联合交付',
    text60: '我们提供与客户紧密合作，提供现场专业知识，并结合近海和离岸服务，以优质高效并极具性价比的方式进行交付。',
    text61: '我们认为，最好的工作方式就是我们共同定义业务案例并与客户一起面对和解决问题。',
    text62: '快速推进模式和合作伙伴生态系统',
    text63: '多年的服务经验使得我们沉淀下大量的可以帮助客户快速推进数字化业务实施落地的知识资产，包括成数以百计的行业解决方案、数百个技术快速搭建平台和产品，以及相关的团队组织模型组织方案，以快速满足客户的需求。',
    text64: '我们还与数百个优秀的合作伙伴生态系统合作，使得我们的综合能力倍增。',
    text65: '您的姓名',
    text66: '联系电话',
    text67: '您的邮箱',
    text68: '咨询内容',
    text69: '提交'
};
