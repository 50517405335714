<!--
    公共列表页面组件
-->
<template>
  <div class="filter-table-box">
      <div class="search-condition">
          <div class="search-condition-list">
              <slot name="searchCondition"></slot>
          </div>
          <div class="search-condition-btn">
              <el-button type="primary" size="medium">查询</el-button>
              <el-button size="medium">重置</el-button>
          </div>
      </div>
      <div class="filter-table-content">
          <div class="filter-table-content-container">
            <div class="filter-table-content-options">
                <slot name="optionBtns">
                    <el-button type="primary" size="medium">新建用户</el-button>
                </slot>
            </div>
            <div class="filter-table-content-table">
                <slot name="table">
                    <el-table
                        :data="tableData"
                        style="width: 100%">
                        <el-table-column
                            prop="date"
                            label="日期"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="姓名"
                            width="180">
                        </el-table-column>
                        <el-table-column
                            prop="address"
                            label="地址">
                        </el-table-column>
                    </el-table>
                </slot>
            </div>
            <div class="filter-table-content-page">
                <el-pagination :total="pageInfo.total" :current-page="pageInfo.current" :page-size="pageInfo.pageSize" @current-change="changePage" background></el-pagination>
            </div>
          </div>
         
      </div>
  </div>
</template>

<script>
export default {
    data () {
        return {
            tableData: [],
            pageInfo: {
                total: 0,
                current: 1,
                pageSize: 10
            }
        }
    },
    methods: {
        changePage(val) {
            this.$emit('changePage', val);
        }
    }
}
</script>

<style lang="less">
    @btnsWidth: 220/192rem;
    @contentPadding: 30/192rem;
    .filter-table-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .search-condition {
            width: 100%;
            min-height: 45/192rem;
            background-color: @content-bg;
            border-radius: 5/192rem;
            display: flex;
            margin-bottom: 10/192rem;
            &-list {
                width: calc(~"100% -  @{btnsWidth}");
                display: flex;
                flex-wrap: wrap;
                padding-top: 10/192rem;
                padding-left: 20/192rem;
                .el-form.el-form--inline {
                    .el-form-item {
                        width: 356/192rem;
                        .el-form-item__content {
                            width: 270/192rem;
                        }
                    }
                }
            }
            &-btn {
                width: 180/192rem;
                padding-top: 10/192rem;
                padding-left: 20/192rem;
            }
        }
        .filter-table-content {
            flex-grow: 1;
            background-color: @content-bg;
            border-radius: 5/192rem;
            &-container {
                width: calc(~"100% - @{contentPadding}");
                height: calc(~"100% - @{contentPadding}");
                padding: 15/192rem;
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                .filter-table-content-options {
                    min-height: 0;
                }
                .filter-table-content-table {
                    flex-grow: 1;
                    margin-bottom: 10/192rem;
                }
                .filter-table-content-page {
                    height: 50/192rem;
                    text-align: right;
                }
            }
        }
    }
</style>