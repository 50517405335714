<template>
  <div class="main-box" :class="'theme-' + theme">
    <Header @changeTheme="changeTheme"></Header>
    <div class="main-content">
        <div class="main-content-left">
            <left-menu></left-menu>
        </div>
        <div class="main-content-right">
            <transition name="fade">
                <router-view></router-view>
            </transition>
        </div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header'
import LeftMenu from '@/components/left-menu.vue'
export default {
    components: {
        Header,
        LeftMenu
    },
    data() {
        return {
            theme: 'blue'
        }
    },
    methods: {
        changeTheme(val) {
            this.theme = val
        }
    }
}
</script>

<style lang="less">
    @header-height: 50/192rem;
    @right-content-padding: 30/192rem;
    .main-box {
        width: 100%;
        height: 100%;
        font-size: 14/192rem;
        background-color: @main-bg;
        .main-content {
            width: 100%;
            height: calc(~"100% -  @{header-height}");
            display: flex;
            .main-content-left {
                height: 100%;
                background-color: @menu-bg;
            }
            .main-content-right {
                height: calc(~"100% -  @{right-content-padding}");
                padding: 15/192rem;
                flex-grow: 1;
                
            }
        }
    }
</style>