export const lang = {
    text1: 'Chongqing Kunzhiyi Technology Co.，Ltd.',
    text2: 'SPECIALIZED IN SOFTWARE DEVELOPMENT, SMART DEVICE OPERATION AND RELATED HARDWARE AND SOFTWARE DEVELOPMENT',
    text3: 'We are adhering to the development concepts of professionalism, dedication, passion and innovation, adhering to the service guideline of customer-oriented and credit-first, and solving the most urgent and practical needs of the users with our own advantages of specializing in enterprise informatization services, and providing perfect services for the users with advanced technology.',
    text4: 'Testing & Digital Assurance',
    text5: 'UNIQUE VALUE PROPOSITION',
    text6: '1. Modern testing service that accelerates time to market',
    text7: 'Accelerate transformation to modern Quality Engineering practices',
    text8: 'AI-enabled Intelligent Testing Platform and unique KZY.tech IP deliver higher levels of automation, greater productivity and speed including Generative AI',
    text9: '2.Outcome-based Testing as a Service delivers greater value',
    text10: 'Unique outcome-based “Pay as you Go” model and allows flexibility to optimize and control expenditures, scale up or down based on changing business demand',
    text11: 'Clients will understand the exact expected results and contracted deliverables prior to making business decisions with less risk',
    text12: '3.End-to-end testing expertise for improved application quality',
    text13: 'Broad services catalog allows 360° view of application quality including functional, integration, performance, reliability, and security testing built-in',
    text14: 'Flexible menu-based approach is easier to manage and provides clients the ability to choose services that will meet their highest prioritized needs',
    text15: '4. Value delivered to 250+ Fortune 500 companies',
    text16: 'Global presence with more than 12,000 testing practitioners located in client proximity and leveraged centers around the world',
    text17: 'Proven success, 40+ years of multi-industry experience',
    text18: 'Testing Advisory',
    text19: '测试咨询',
    text20: 'Intelligent Test Automation',
    text21: '自动化智能测试',
    text22: 'Intelligent Testing as a Service',
    text23: '智能测试即服务',
    text24: 'Apps Security on Demand',
    text25: '按需提供应用程序安全',
    text26: 'Applications Modernization',
    text27: 'CLIENT VALUE PROPOSITION',
    text28: 'Eliminate stubborn technical debt – reduce cost 39%',
    text29: 'Our 3D Discovery, Quick Transformation Engine and Factory Fabric will facilitate tackling technical debt reduction, including mainframes, exotic applications and old applications that have been moved to a new cloud',
    text30: 'Ignite innovation – modernize the 47% of apps out of date',
    text31: 'Our Gordian Knot Analyzer will forensically locate and neutralize those risks associated with modernization and provide KZY.techs advanced application scaling and load balancing solutions for cloud or on prem',
    text32: 'Deliver New IT requirements 42% faster',
    text33: 'Initial jumpstart workshop delivers an action plan in only 10 days',
    text34: 'KZY.tech IP of a 650+ client AI powered data bank can provide the optimal solution for your business processes, inheriting best in class solutions',
    text35: 'Minimize business risk – fix the 45% of unresilient apps',
    text36: 'Our Gordian Knot Analyzer will forensically locate and neutralize those risks associated with modernization and provide KZY.techs advanced application scaling and load balancing solutions for cloud or on prem',
    text37: 'Managed Applications',
    text38: 'CLIENT VALUE PROPOSITION',
    text39: '1. Committed Client Savings',
    text40: 'Reduce RUN costs (up to 40%) through a sustainable commercial model',
    text41: 'We Simplify, Automate and Optimize applications management to achieve operational efficiencies enabling a Run to Transform approach.',
    text42: '2. Highly accelerated realized Value',
    text43: 'Catalyst– robust collection of KZY.tech Assets and Accelerators to expedite speed-to-value and technical debt reduction',
    text44: 'Focus on output driven metrics aligned with business and IT priorities to provide business agility',
    text45: '3. Operational Stability',
    text46: 'AI enabled automation platforms (ASA and Platform X) enriched with leading edge partner products provides operation resiliency',
    text47: 'KZY.tech Digital Command Center eliminates operational silos and bridge service management gaps with superior observability reducing MTTR.',
    text48: '4. Scalable and Flexible Engagement Models',
    text49: 'Proven industrialized methodology and global delivery model adapted across varied industries and technologies',
    text50: ' Value delivered to Fortune 1000 customers by deep domain and technical experts',
    text51: 'Modern Applications Development',
    text52: 'DEVELOPMENT OF MODERN DIGITAL APPLICATIONS',
    text53: 'Platform Expertise',
    text54: ' KZY.tech leads our clients transformation to a next generation "Platform Driven Business" optimizing their operating model to achieve the speed & agility required to compete in the marketplace. ',
    text55: ' KZY.tech brings implementation experience and accelerator assets to help organizationally adapt & technically transform our clients to address a cyber-physical software defined world. ',
    text56: 'Commercial Adaptability',
    text57: ' Our willingness to take risk and arrive at a sustainable commercial model that works for our customers and KZY.tech. ',
    text58: ' Our Point of View is that a Platform Approach provides a means of optimizing Total Cost of Ownership from delivery of change through to operational service.',
    text59: 'Collaborative Delivery',
    text60: ' We work collaboratively with our clients providing on-site expertise and combine with near and off-shore to deliver at a highly efficient price point.',
    text61: ' Our best work is where we jointly define a business case and solve a problem together with our clients',
    text62: 'Accelerators & Partner Ecosystem',
    text63: ' Our assets include blueprints, hundreds of technical accelerators and organizational models to help accelerate delivery of our client’s requirements.',
    text64: ' We collaborate across our partner ecosystem to force multiply our combined capability  ',
    text65: 'Your Name',
    text66: 'Telephone Number',
    text67: 'Your Email',
    text68: 'Consultation',
    text69: 'Submit'
};
