<template>
    <div class="leave-message-box">
        <el-form :inline="true" :model="defaultform" class="demo-form-inline">
            <el-form-item label="姓名">
                <el-input v-model="defaultform.userName" placeholder="" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="searchAll">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
                prop="name"
                label="姓名"
                width="180">
            </el-table-column>
            <el-table-column
                prop="phone"
                label="联系电话"
                width="180">
            </el-table-column>
            <el-table-column
                prop="email"
                label="邮箱"
                width="180">
            </el-table-column>
            <el-table-column
                prop="consult"
                label="咨询内容">
            </el-table-column>
        </el-table>
        <el-pagination
            class="pagination"
            background
            :current-page="page.currentPage"
            @current-change="changePage"
            layout="prev, pager, next"
            :page-size="page.pageSize"
            :total="page.totol">
        </el-pagination>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            page: {
                currentPage: 1,
                totol: 0,
                pageSize: 10
            },
            defaultform: {
                userName: ''
            },
            tableData: []
        }
    },
    created() {
        localStorage.setItem('token', '123456')
        localStorage.setItem('userkey', 'test')
    },
    mounted() {
        this.search()
    },
    methods: {
        searchAll() {
            this.page.currentPage = 1;
            this.search()
        },
        changePage(val) {
            console.log(val)
            this.page.currentPage = val;
            this.search()
        },
        search() {
            this.$axios.post(this.baseUrl + '/leavingMessage/MessageList', {
                "page": {
                    "pageNumber": this.page.currentPage,
                    "pageSize": this.page.pageSize
                },
                "requestData": {
                    "name": this.defaultform.userName
                }
            })
            .then((response) => {
                console.log(response)
                if (response && response['data'] && response['data']['data']) {
                    this.tableData = response['data']['data']['list']
                    this.page.totol = response['data']['data']['total']
                }
            })
        }
    }
}
</script>
<style lang='less'>
@left-content: 10px;
.leave-message-box {
    height: 100%;
    .pagination {
        text-align: right;
        margin-top: 20/192rem;
    }
    width: calc(~"100% -  @{left-content}");
}
</style>