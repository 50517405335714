<template>
  <div class="role-manager-box">
      角色管理
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
    .role-manager-box {}
</style>