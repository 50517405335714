<template>
  <div class="user-manager-box">
      <FilterTable>
          <el-form :model="defaultform" inline label-width="80px" slot="searchCondition">
                <el-form-item label="用户名">
                    <el-input v-model="defaultform.userName" size="medium" placeholder="请输入"></el-input>
                </el-form-item>
              </el-form>
      </FilterTable>
  </div>
</template>

<script>
import FilterTable from '@/components/filter-table'
export default {
    components: {
        FilterTable
    },
    data() {
        return {
            defaultform: {
                userName: ''
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .user-manager-box {
        width: 100%;
        height: 100%;
    }
</style>