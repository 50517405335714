<template>
  <div class="left-menu-box" :class="!isCollapse ? 'collapse' : ''">
      <el-menu default-active="1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
          <template v-for="item in menuList">
              <el-submenu :key="item.resourceId" :index="item.resourceId" v-if="item.children">
                <template slot="title">
                    <em class="el-icon-location"></em>
                    <span slot="title">{{item.name}}</span>
                </template>
                <template v-for="child in item.children">
                    <el-menu-item :key="child.resourceId" :index="child.resourceId" v-if="!child.children" @click="toPage(child)">{{child.name}}</el-menu-item>
                    <el-submenu :key="child.resourceId" :index="child.resourceId" v-else>
                        <span slot="title">{{child.name}}</span>
                        <el-menu-item v-for="lastChild in child.children" :key="lastChild.resourceId" @click="toPage(lastChild)" :index="lastChild.resourceId">{{lastChild.name}}</el-menu-item>
                    </el-submenu>
                </template>
            </el-submenu>
            <el-menu-item :key="item.resourceId" :index="item.resourceId" v-else @click="toPage(item)">
                <em class="el-icon-setting"></em>
                <span slot="title">{{item.name}}</span>
            </el-menu-item>
          </template>
    </el-menu>
    <div class="mennu-collapse-btn" @click="isCollapse = !isCollapse">
        <em :class="isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"></em>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters(['menuList'])
    },
    data() {
      return {
        isCollapse: true
      };
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      toPage(item) {
          this.$router.push({name: item.rescourceCode});
      }
    }
}
</script>

<style lang="less" scoped>
    .left-menu-box {
        height: 100%;
        position: relative;
        // max-width: 200/192rem;
        width: 70px;
        border-right: solid 1px #e6e6e6;
        box-sizing: border-box;
        &.collapse {
            width: 200/192rem;
        }
        .mennu-collapse-btn {
            width: 100%;
            height: 30/192rem;
            position: absolute;
            bottom: 0;
            text-align: center;
        }
        .el-menu {
            border: none;
        }
        .el-submenu .el-menu-item {
            padding: 0;
            min-width: 100/192rem;
        }
    }
</style>
