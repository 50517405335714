<template>
  <div class="header-box">
      <div class="header-left">
          <img class="img-logo" src="../assets/img-17.png" alt="">
          <span class="system-name">{{systemName}}</span>
      </div>
      <div class="header-middle"></div>
      <div class="header-right">
          <!-- <el-dropdown @command="handleLang">
            <span class="el-dropdown-link">
                {{langName}}<em class="el-icon-arrow-down el-icon--right"></em>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in langList" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
                <em class="el-icon-magic-stick"></em> {{themeName}}<em class="el-icon-arrow-down el-icon--right"></em>
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in themes" :key="index" :command="item.value">{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <em class="el-icon-switch-button" title="退出登录" @click="logout"></em> -->
      </div>
  </div>
</template>

<script>
export default {
    computed: {
        themeName() {
            let result = ''
            for(let i = 0; i < this.themes.length; i++) {
                if (this.themeId === this.themes[i].value) {
                    result = this.themes[i].label;
                    break
                }
            }
            return result
        },
        langName() {
            let result = ''
            for(let i = 0; i < this.langList.length; i++) {
                if (this.langId === this.langList[i].value) {
                    result = this.langList[i].label;
                    break
                }
            }
            return result
        }
    },
    data() {
        return {
            themeId: 'blue',
            themes: [
                {label: '蓝色', value: 'blue'},
                {label: '绿色', value: 'green'}
            ],
            langId: 'zh-CN',
            langList: [
                {label: '中文简体', value: 'zh-CN'},
                {label: 'English', value: 'en-US'}
            ]
        }
    },
    methods: {
        handleCommand(val) {
            this.themeId = val;
            this.$emit('changeTheme', this.themeId);
        },
        handleLang(val) {
            this.langId = val;
            this.$i18n.locale = val;
        },
        logout() {
            localStorage.removeItem('token')
            localStorage.removeItem('userKey')
            this.$router.push({name: 'login'})
        }
    }
}
</script>

<style lang="less" scoped>
@header-heihgt: 50/192rem;
    .header-box {
        width: 100%;
        height: @header-heihgt;
        background-color: @main-color;
        display: flex;
        .header-left {
            width: 300/192rem;
            line-height: 100%;
            display: flex;
            .img-logo {
                height: 38/192rem;
                margin: 6/192rem 12/192rem;
            }
            .system-name {
                height: 100%;
                font-size: 28/192rem;
                color: @title-color;
                line-height: @header-heihgt;
            }
        }
        .header-middle {
            text-align: center;
            flex-grow: 1;
        }
        .header-right {
            width: 300/192rem;
            color: @title-color;
            text-align: right;
            margin-right: 1em;
            line-height: @header-heihgt;
            .el-dropdown {
                margin-left: 1em;
            }
            .el-icon-switch-button {
                font-weight: bold;
                font-size: 16/192rem;
                cursor: pointer;
                margin-left: 0.5em;
            }

            .el-dropdown-link {
                color: @title-color;
            }
        }
    }
</style>